import axios from 'axios';
import Cookies from 'js-cookie';
import { getLocalItem, setLocalItem } from './localState';

export default {
  state: {
    loading: false,
    btsLogo: 'bts-logo.png',
    systems: [
      {
        id: 'PHL',
        logo: 'indego-logo.png',
        signup: 'https://www.rideindego.com/buy-a-pass/#',
      },
      {
        id: 'LAX',
        logo: 'metro-logo.png',
        signup: 'https://bikeshare.metro.net/signup/#',
      },
      {
        id: 'LAS',
        logo: 'rtc-logo.png',
        signup: 'https://bikeshare.rtcsnv.com/signup/#',
      },
    ],
    selectedSystem: getLocalItem('selectedSystem'),
    subscriptionTypes: [],
    stations: [],
  },
  mutations: {
    setSystem(state, id) {
      if (state.systems.find(sys => sys.id === id)) {
        setLocalItem('selectedSystem', id);
        state.selectedSystem = id;
        window.location.reload();
      }
    },
    setSubscriptionTypes(state, types) {
      state.subscriptionTypes = types;
    },
    setStations(state, stations) {
      state.stations = stations;
    },
    setLoading(state, loading) {
      state.loading = loading;
    }
  },
  getters: {
    currentSystem: state => state.systems.find(sys => sys.id === state.selectedSystem),
  },
  actions: {
    loadSubscriptionTypes: async function ({ commit, state }) {
      const types = await axios({
        method: 'GET',
        url: '/.netlify/functions/subscriptionTypes',
        params: {
          system: state.selectedSystem
        },
        headers: {
          'Authorization': `Bearer ${Cookies.get('btsAccessToken')}`
        }
      });

      commit('setSubscriptionTypes', types.data);
    },
    loadStations: async function ({ state, commit }) {
      const stations = await axios({
        method: 'GET',
        url: '/.netlify/functions/stations',
        params: {
          system: state.selectedSystem
        },
        headers: {
          'Authorization': `Bearer ${Cookies.get('btsAccessToken')}`
        }
      });

      commit('setStations', stations.data);
    }
  }
};
