import Vue from 'vue'
import VueRouter from 'vue-router'

// Router Configurations
import beforeEach from './beforeEach';

// Routes
import Main from './main';
import Dashboard from './dashboard';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    Main,
    Dashboard
  ]
});

router.beforeEach(beforeEach);
export default router;
