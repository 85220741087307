import Dashboard from '../views/Dashboard.vue';

export default {
    path: '',
    name: 'Dashboard',
    component: Dashboard,
    children: [
      {
        name: 'BikeshareForBusiness',
        path: 'bikeshare-for-business',
        component: () => import('../components/BikeshareForBusiness.vue')
      },
      {
        name: 'SegmentedPushNotifications',
        path: 'push-notifications',
        component: () => import('../components/SegmentedPushNotifications.vue')
      }
    ]
};
