<template>
  <div class="p-4">
    <h1 class="text-2xl">Bicycle Transit Systems Admin</h1>
    <div class="mt-4">
      <div class="w-full shadow-md p-4 bg-white cursor-pointer hover:shadow-xl transition my-2" v-for="opt in options" :key="opt.route" @click="selectOption(opt)">
        <h2 class="font-bold">{{ opt.name }}</h2>
        <p>{{ opt.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          name: 'Bikeshare for Business',
          route: 'BikeshareForBusiness',
          description: 'Manage bikeshare for business accounts, enrollments, and users.',
        },
        {
          name: 'Segmented Push Notifications',
          route: 'SegmentedPushNotifications',
          description: 'Segment and send push notifications to users.',
        }
      ]
    }
  },
  methods: {
    selectOption({ route }) {
      this.$router.push({ name: route });
    }
  }
}
</script>
