import axios from 'axios';
import Cookies from 'js-cookie';

export default {
  state: {
    companies: [],
  },
  mutations: {
    setCompanies(state, companies) {
      state.companies = companies;
    }
  },
  actions: {
    saveEnrolledUser: async function({ state, commit, dispatch }, user) {

      commit('setLoading', true);

      await axios({
        method: 'POST',
        url: '/.netlify/functions/saveEnrolledUser',
        params: {
          system: state.selectedSystem
        },
        headers: {
          'Authorization': `Bearer ${Cookies.get('btsAccessToken')}`
        },
        data: user
      });

      dispatch('loadEnrolledCompanies');
    },
    saveEnrollmentPeriod: async function({ state, commit, dispatch }, enrollment) {
      commit('setLoading', true);

      const period = await axios({
        method: 'POST',
        url: '/.netlify/functions/saveEnrollmentPeriod',
        params: {
          system: state.selectedSystem
        },
        headers: {
          'Authorization': `Bearer ${Cookies.get('btsAccessToken')}`
        },
        data: enrollment
      });

      dispatch('loadEnrolledCompanies');

      return period.data;
    },
    saveCompany: async function({ state, dispatch, commit }, company) {
      commit('setLoading', true);

      await axios({
        method: 'POST',
        url: '/.netlify/functions/saveCompany',
        params: {
          system: state.selectedSystem
        },
        headers: {
          'Authorization': `Bearer ${Cookies.get('btsAccessToken')}`
        },
        data: company
      });

      dispatch('loadEnrolledCompanies');
    },
    loadEnrolledCompanies: async function({ commit, state }) {
      commit('setLoading', true);

      const companies = await axios({
        method: 'GET',
        url: '/.netlify/functions/enrolledCompanies',
        params: {
          system: state.selectedSystem
        },
        headers: {
          'Authorization': `Bearer ${Cookies.get('btsAccessToken')}`
        }
      });

      commit('setLoading', false);
      commit('setCompanies', companies.data);
    }
  }
};
