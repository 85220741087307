import Cookies from 'js-cookie';

const defaultRouting = function(to, from, next) {
  if(to.path === '/') {
    next({ name: 'Dashboard' });
  }
}

const authGuard = function(to, from, next) {

  if(to.query['access_token']) {
    Cookies.set('btsAccessToken', to.query['access_token'], { expires: 1 });
  }

  if(Cookies.get('btsAccessToken')) {
    if(to.query['access_token']) {
      next({ path: to.path }); // clear access_token param
    } else {
      next();
    }
 } else {
    if(to.name !== 'Login') {
      next({ name: 'Login' });
    } else {
      next();
    }
  }

}

export default function beforeEach(to, from, next) {
  authGuard(to, from, next);
  defaultRouting(to, from, next);
}
