<template>
  <div class="p-8">
    <div class="lg:flex w-full mt-4">
      <div class="w-full lg:pr-2">
        <div class="bg-white p-4 shadow-md">
          <h1 class="text-xl mb-2">Push Notification</h1>
          <div class="mt-2">
            <label class="font-medium">Title</label>
            <input type="text" placeholder="Push Notification Title" v-model="notification.title" />
          </div>
          <div class="mt-2">
            <label class="font-medium">Body</label>
            <input type="text" placeholder="Push Notifcation Body" v-model="notification.body" />
          </div>
          <div class="mt-2">
            <label class="font-medium">Category</label>
            <select v-model="notification.category">
              <option v-for="category in notificationCategory" :key="category.value" :value="category.value">
              {{ category.name }}
              </option>
            </select>
          </div>
          <div class="mt-2">
            <label class="font-medium">Action</label>
            <select v-model="notification.action">
              <option v-for="action in notificationActions" :key="action.value" :value="action.value">
              {{ action.name }}
              </option>
            </select>
          </div>
          <div class="mt-2" v-if="notification.action === 'link'">
            <label class="font-medium">Custom Link</label>
            <input type="text" v-model="notification.link" placeholder="Custom Link" />
          </div>
          <div class="mt-2">
            <label class="font-medium">Pass Type</label>
            <select v-model="notification.pass">
              <option value="null" selected>All Pass Types</option>
              <option v-for="p in passes" :value="p.subscriptionTypeId" :key="p.subscriptionTypeId">{{ p.name }}</option>
            </select>
          </div>
          <div class="mt-2">
            <label class="font-medium">Frequent Station</label>
            <select v-model="notification.frequentStation">
              <option value="null" selected>All Stations</option>
              <option v-for="st in stations" :key="st.properties.kioskId" :value="st.properties.kioskId">
                {{ st.properties.name }}
              </option>
            </select>
          </div>
          <div class="mt-2">
            <label class="font-medium">Account Status</label>
            <select v-model="notification.active">
              <option value="null" selected>Active and Expired</option>
              <option value="true">Active</option>
              <option value="false">Expired</option>
            </select>
          </div>
          <div class="mt-2 flex justify-between items-center md:justify-start md:flex-row-reverse">
            <button class="bg-green-100 hover:bg-green-200 px-4 py-2 outline-none" @click="sendPushNotification" :disabled="disableSend">
                Send Notification
            </button>
            <span class="text-gray-500 text-xs md:mr-4 md:text-base">Current segment includes {{ segmentCount }} users</span>
          </div>
        </div>
      </div>
      <div class="w-full lg:pr-2 mt-4 lg:mt-0">
        <div class="bg-white p-4 shadow-md">
          <h2 class="text-lg mb-2">Test Notification</h2>
          <div>
            <label class="font-medium">BCycle User ID</label>
            <input type="text" placeholder="Send push notification to user" v-model="testUserId" />
          </div>
          <div class="mt-2 flex justify-end">
            <button class="bg-gray-100 hover:bg-gray-200 px-4 py-2 outline-none" :disabled="disableSend" @click="sendPushNotificationTest">Send Test Notification</button>
          </div>
        </div>
        <div class="bg-white p-4 shadow-md mt-4">
          <h2 class="text-lg mb-2">Recent Notifications</h2>
          <div
            class="flex justify-between items-center py-2 px-4 border-l-4 my-1 cursor-pointer hover:bg-gray-100"
            v-for="notification in recentNotifications"
            :key="notification.id"
            @click="loadNotification(JSON.parse(notification.notification))">
            <div class="flex flex-col">
              <span>{{ JSON.parse(notification.notification)['title'] }}</span>
              <span>{{ JSON.parse(notification.notification)['body'] }}</span>
            </div>
            <div>
              <span>{{ formatNotificationDate(notification.date_added, 'M/D/YYYY') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

const DEFAULT_NOTIFICATION = {
  title: '',
  body: '',
  action: 'account',
  category: 'account',
  pass: null,
  link: null,
  active: null,
  frequentStation: null,
};

export default {
  mounted() {
    this.$store.dispatch('loadSubscriptionTypes');
    this.$store.dispatch('loadStations');
    this.$store.dispatch('loadPushNotifications');
    setTimeout(() => this.updateSegmentCount(), 2500);
  },
  data() {
    return {
      updateCount: false,
      segmentCount: 0,
      testUserId: null,
      notificationCategory: [
        {
          name: 'Account',
          value: 'account'
        },
        {
          name: 'Rewards',
          value: 'rewards_fulfillment'
        },
        {
          name: 'Trips',
          value: 'trips'
        },
      ],
      notificationActions: [
        {
          name: 'Account',
          value: 'account'
        },
        {
          name: 'Rewards',
          value: 'rewards_fulfillment'
        },
        {
          name: 'Trips',
          value: 'trips'
        },
        {
          name: 'Custom Link',
          value: 'link'
        }
      ],
      notification: { ...DEFAULT_NOTIFICATION }
    }
  },
  computed: {
    passes() {
      const vm = this;
      return vm.$store.state.subscriptionTypes;
    },
    stations() {
      const vm = this;
      return vm.$store.state.stations;
    },
    disableSend() {
      const vm = this;
      return !vm.notification.title || !vm.notification.body;
    },
    recentNotifications() {
      const vm = this;
      return vm.$store.state.recentNotifications;
    }
  },
  methods: {
    async sendPushNotification() {
      const vm = this;
      if(!vm.disableSend && confirm(`Send push notification to ${vm.segmentCount} users?`)) {
        await vm.$store.dispatch('sendPushNotification', vm.notification);
        vm.notification = { ...DEFAULT_NOTIFICATION };
      }
    },
    async sendPushNotificationTest() {
      const vm = this;
      if(!vm.disableSend && vm.testUserId) {
        await vm.$store.dispatch('sendPushNotificationTest', { notification: vm.notification, userId: vm.testUserId });
        alert(`Push notification sent to user (${vm.testUserId}).`);
      }
    },
    formatNotificationDate(dateStr, format) {
      return moment.utc(dateStr).format(format);
    },
    loadNotification(notification) {
      const vm = this;
      vm.notification = notification;
    },
    async updateSegmentCount() {
      const vm = this;
      if(!vm.updateCount) {
        vm.updateCount = true;
        const count = await vm.$store.dispatch('getPushNotificationSegmentCount', vm.notification);
        vm.segmentCount = count;
        vm.updateCount = false;
      }
    }
  },
  watch: {
    'notification.action'(value) {
      const vm = this;
      if(vm.notification.action !== 'link') {
        vm.notification.link = value;
      }
    },
    'notification.pass'() {
      const vm = this;
      vm.updateSegmentCount();
    },
    'notification.active'() {
      const vm = this;
      vm.updateSegmentCount();
    },
    'notification.frequentStation'() {
      const vm = this;
      vm.updateSegmentCount();
    }
  }
}
</script>
