<template>
  <div class="p-8">
    <div class="lg:flex w-full mt-4">
      <div class="w-full lg:pr-2">
        <div class="bg-white p-4 shadow-md">
          <h1 class="text-xl mb-2">Bikeshare For Business</h1>
          <select 
            v-model="businessSelected" 
            class="w-full text-base py-2 px-4 outline-none bg-white hover:bg-gray-50 rounded-none border-b-2 border-gray-100">
            <option :value="null">Add New Business</option>
            <option v-for="b in companies" :value="b.id" :key="b.id">{{ b.company }}</option>
          </select>
        </div>

        <div class="mt-4 w-full bg-white p-4 shadow-md">
          <div>
            <label class="font-medium">Company</label>
            <input
              @input="() => companyDetailChange = true"
              v-model="currentCompany.company"
              placeholder="Company Name"
              class="mt-2"
              type="text" />
          </div>

          <div class="mt-2">
            <label class="font-medium">Pass Type</label>
            <select 
              @change="() => companyDetailChange = true"
              v-model="currentCompany.pass_type"
              class="w-full mt-2 outline-none text-base p-2 border-b-2 border-gray-100 hover:bg-gray-50"
              v-bind:class="{ 'text-gray-400': (currentCompany.pass_type === null) }">
              <option class="text-gray-50" value="null" disabled selected>Membership</option> 
              <option v-for="p in passes" :value="p.subscriptionTypeId" :key="p.subscriptionTypeId">{{ p.name }}</option>
            </select>
          </div>

          <div class="mt-2">
            <label class="font-medium">Account Domains</label>
            <input
              placeholder="Valid Account Domains (Comma Separated)"
              @input="() => companyDetailChange = true"
              v-model="currentCompany.domains"
              v-bind:class="{ 'text-gray-400': (currentCompany.domains === '') || (currentCompany.domains === null) }"
              class="mt-2"
              type="text" />
          </div>

          <div class="mt-2">
            <label class="font-medium">Admin Emails</label>
            <input
              placeholder="Account Admin Emails (Comma Separated)"
              @input="() => companyDetailChange = true"
              v-model="currentCompany.admins"
              class="mt-2" type="text" />
          </div>

          <div class="mt-2">
            <label class="font-medium">Status</label>
            <select 
              v-model="currentCompany.active"
              @change="() => companyDetailChange = true"
              class="mt-2">
              <option :value="true">Active</option>
              <option :value="false">Disabled</option>
            </select>
          </div>

          <div class="mt-4">
            <button 
              @click="saveCompany"
              v-if="showCompanySave"
              class="bg-green-100 hover:bg-green-200 px-4 py-2 outline-none mr-2">
                Save Company
            </button>
          </div>
        </div>

        <div v-if="businessSelected" class="w-full bg-white p-4 shadow-md mt-4">
          <div class="flex justify-between items-center mb-4">
            <h2>Enrollment Period</h2>
            <div>
              <button 
                @click="setPeriod()" 
                class="bg-green-100 hover:bg-green-200 px-4 py-2 outline-none ml-1 text-xs md:text-base">
                Set Period
              </button>
            </div>
          </div>
          <div class="flex mt-2">
            <div class="w-full mr-1">
              <label class="ml-2 font-medium">Start Period</label>
              <input type="date" v-model="enrollmentStart" />
            </div>
            <div class="w-full ml-1">
              <label class="ml-2 font-medium">End Period</label>
              <input type="date" v-model="enrollmentEnd" />
            </div>
          </div>
          <div class="mt-2">
            <div 
              v-for="period in enrollmentPeriods" 
              :key="period.id"
              class="flex justify-between w-full">
              <div @click="removePeriod(period)" class="flex justify-between items-center py-2 px-4 border-l-4 my-1 hover:bg-red-50 cursor-pointer w-full">
                <div class="flex flex-col">
                  <span v-if="period.enrollment_end">{{ formatEnrollmentDate(period.enrollment_start, 'M/D/YYYY') }} - {{ formatEnrollmentDate(period.enrollment_end, 'M/D/YYYY') }}</span>
                  <span>{{ period.enrollment_code }}</span>
                </div>
              </div>
              <div class="w-3/12 flex justify-center items-center cursor-pointer hover:bg-gray-50" @click="evt => copyEnrollmentLink(period.enrollment_code, evt)">
                <span class="text-xs px-2 md:text-base md:px-0">
                  Copy Link
                </span>
              </div>
            </div>
            <div 
              @click="setPeriod(true)"
              class="flex justify-between py-2 px-4 bg-gray-50 hover:bg-gray-100 my-1 cursor-pointer">
              <span class="w-full text-center">Generate Single-Use Code</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="businessSelected" class="w-full mt-4 lg:mt-0 lg:pl-2">
        <div class="w-full bg-white p-4 shadow-md">
          <div class="flex justify-between items-center mb-2">
            <h2>Employees</h2>
          </div>
          <div class="flex">
            <button 
              @click="() => employeeActiveFilter = true"
              :class="{ 'border-b-2': employeeActiveFilter, 'bg-gray-50': employeeActiveFilter }"
              class="w-full bg-white hover:bg-gray-50 mr-1 px-4 py-2 outline-none">Active</button>
            <button 
              @click="() => employeeActiveFilter = false"
              :class="{ 'border-b-2': !employeeActiveFilter, 'bg-gray-50': !employeeActiveFilter }"
              class="w-full bg-white hover:bg-gray-50 ml-1 px-4 py-2 outline-none">Disabled</button>
          </div>
          <div class="mt-2 flex">
            <input type="text" placeholder="BCycle User ID" v-model="employeeId" />
            <button @click="addEmployee" class="bg-gray-50 hover:bg-gray-100 ml-1 px-4 py-2 outline-none w-48 text-sm md:text-base">Add Employee</button>
          </div>
          <div class="mt-2">
            <div 
              v-for="employee in employees" 
              :key="employee.email"
              @click="toggleEmployee(employee, !employee.active)"
              :class="{ 
                'hover:bg-red-50': employee.active === true,
                'hover:bg-green-50': employee.active === false,
                'cursor-pointer': (employee.active !== null)
              }"
              class="flex justify-between items-center py-2 px-4 border-l-4 my-1">
              <div class="flex flex-col text-sm md:text-base">
                <span>{{ employee.first_name + ' ' + employee.last_name }}</span>
                <span>{{ employee.email }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  mounted() {
    this.$store.dispatch('loadSubscriptionTypes');
    this.$store.dispatch('loadEnrolledCompanies');
  },
  data() {
    return {
      businessSelected: null,
      employeeActiveFilter: true,
      companyDetailChange: false,
      enrollmentStart: null,
      enrollmentEnd: null,
      employeeId: null,
    }
  },
  methods: {
    saveCompany() {
      const vm = this;
      vm.$store.dispatch('saveCompany', {
        id: vm.businessSelected,
        company: vm.currentCompany.company,
        pass_type: vm.currentCompany.pass_type,
        active: vm.currentCompany.active,
        admins: vm.currentCompany.admins,
        domains: vm.currentCompany.domains
      });
      vm.companyDetailChange = false;
    },
    addEmployee: async function() {
      const vm = this;

      if(vm.employeeId) {
        const confirmMsg = `Add user (${vm.employeeId}) to ${vm.currentCompany.company}?\nNote: Their current pass type will be unchanged.`;

        if(confirm(confirmMsg)) {
          const period = await vm.$store.dispatch('saveEnrollmentPeriod', {
            'company_id': vm.currentCompany.id,
            'enrollment_start': new Date().toLocaleDateString(),
            'enrollment_end': null,
          });

          await vm.$store.dispatch('saveEnrolledUser', {
            'user_id': vm.employeeId,
            'active': vm.employeeActiveFilter,
            'enrollment_code': period['enrollment_code']
          });

          vm.employeeId = null;
        }
      }

      
    },
    setPeriod: async function(singleUse = false) {
       const vm = this;
       if(singleUse || (vm.enrollmentStart && vm.enrollmentEnd)) {
        if(!singleUse && vm.enrollmentPeriods.find(ep => ep.enrollment_end != null) && !confirm('End the current period?')) {
          return;
        }

        await vm.$store.dispatch('saveEnrollmentPeriod', {
          company_id: vm.currentCompany.id,
          enrollment_start: singleUse ? new Date().toLocaleDateString() : vm.formatEnrollmentDate(vm.enrollmentStart),
          enrollment_end: singleUse ? null : vm.formatEnrollmentDate(vm.enrollmentEnd)
        })

        vm.enrollmentStart = null;
        vm.enrollmentEnd = null;

       }
    },
    removePeriod: async function(period) {
      const vm = this;
      if(confirm('End enrollment period early?')) {
        await vm.$store.dispatch('saveEnrollmentPeriod', {
          id: period.id,
          company_id: vm.currentCompany.id,
          enrollment_end: new Date().toLocaleDateString(),
        });
      }
    },
    toggleEmployee: async function(employee, active) {
      const vm = this;
      let msg = 'Activate employee?';
      if(employee.active === null) {
        msg = active ? 'Accept' : 'Deny';
      }
      if(!active) {
        msg = 'Disable employee?\nThis will disable their auto renew.';
      }
      if(confirm(msg)) {
        await vm.$store.dispatch('saveEnrolledUser', {
          'user_id': employee.id,
          'active': !employee.active,
        });
      }
    },
    formatEnrollmentDate(dateStr, format) {
      return moment.utc(dateStr).format(format);
    },
    copyEnrollmentLink(code, evt) {
      const vm = this;
      const website = vm.$store.getters.currentSystem.signup;
      const original = evt.target.firstChild.textContent;

      const link = `${website}/${vm.currentCompany.pass_type}/discount/${code}`;
      navigator.clipboard.writeText(link);
      evt.target.firstChild.textContent = 'Copied';
      setTimeout(() => {
        evt.target.firstChild.textContent = original;
      }, 1000);
    }
  },
  computed: {
    currentCompany() {
      const vm = this;
      const company = vm.$store.state.companies.filter(({ id }) => id === vm.businessSelected).pop();
      return  {
        company: '',
        pass_type: null,
        active: true,
        admins: '',
        domains: '',
        ...company
      };
    },
    companies() {
      const vm = this;
      return vm.$store.state.companies || [];
    },
    passes() {
      const vm = this;
      return vm.$store.state.subscriptionTypes;
    },
    enrollmentPeriods() {
      const vm = this;
      return vm.currentCompany['enrollment_periods'];
    },
    employees() {
      const vm = this;
      return (vm.currentCompany.employees || []).filter(emp => {
        return emp.active === vm.employeeActiveFilter;
      });
    },
    showCompanySave() {
      const vm = this;
      return vm.companyDetailChange && vm.currentCompany.company;
    },
    selectedSystem() {
      const vm = this;
      return vm.$store.state.selectedSystem;
    }
  },
  watch: {
    businessSelected() {
      const vm = this;
      vm.companyDetailChange = false;
    },
    selectedSystem() {
      this.$store.dispatch('loadSubscriptionTypes');
      this.$store.dispatch('loadEnrolledCompanies');
    }
  }
}
</script>
