import axios from 'axios';
import Cookies from 'js-cookie';

export default {
  state: {
    recentNotifications: [],
  },
  mutations: {
    setRecentNotifications(state, notifications) {
      state.recentNotifications = notifications;
    }
  },
  actions: {
    loadPushNotifications: async function({ state, commit }) {
      commit('setLoading', true);

      const notifications = await axios({
        method: 'GET',
        url: '/.netlify/functions/pushNotification',
        params: {
          system: state.selectedSystem
        },
        headers: {
          'Authorization': `Bearer ${Cookies.get('btsAccessToken')}`
        }
      });

      commit('setLoading', false);
      commit('setRecentNotifications', notifications.data);
    },
    sendPushNotificationTest: async function({ state, commit, dispatch }, { notification, userId }) {
      commit('setLoading', true);

      await axios({
        method: 'POST',
        url: '/.netlify/functions/pushNotification',
        params: {
          system: state.selectedSystem,
          userId
        },
        headers: {
          'Authorization': `Bearer ${Cookies.get('btsAccessToken')}`
        },
        data: notification
      });

      commit('setLoading', false);
      dispatch('loadPushNotifications');

      return true;
    },
    sendPushNotification: async function({ state, commit, dispatch }, notification) {
      commit('setLoading', true);

      await axios({
        method: 'POST',
        url: '/.netlify/functions/pushNotification',
        params: {
          system: state.selectedSystem
        },
        headers: {
          'Authorization': `Bearer ${Cookies.get('btsAccessToken')}`
        },
        data: notification
      });

      commit('setLoading', false);
      dispatch('loadPushNotifications');

      return true;
    },
    getPushNotificationSegmentCount: async function({ state, commit }, segment) {
      commit('setLoading', true);

      const count = await axios({
        method: 'POST',
        url: '/.netlify/functions/pushNotificationSegmentCount',
        params: {
          system: state.selectedSystem
        },
        headers: {
          'Authorization': `Bearer ${Cookies.get('btsAccessToken')}`
        },
        data: segment
      });

      commit('setLoading', false);

      return count.data.count;
    }
  }
};
