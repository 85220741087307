import Cookies from 'js-cookie';
import axios from 'axios';
import router from '../router/index';

export default {
  state: {
    user: null
  },
  mutations: {
    updateUser(state, user) {
      state.user = user;
    }
  },
  actions: {
    login() {
      window.location.replace("/.netlify/functions/login");
    },
    logout({ commit }) {
      Cookies.remove('btsAccessToken');
      window.location.replace("/.netlify/functions/logout");
      setTimeout(() => commit('updateUser', null), 1000);
    },
    loadUser: async function({ state, commit }) {
      if(!state.user) {
        try {
          const user = await axios({
            method: 'GET',
            url: '/.netlify/functions/user',
            headers: {
              'Authorization': `Bearer ${Cookies.get('btsAccessToken')}`
            }
          });

          if(user.data.email) {
            commit('updateUser', user.data);
          } else {
            Cookies.remove('btsAccessToken');
            router.push({ path: '/' });
          }
        } catch(err) {
          Cookies.remove('btsAccessToken');
          router.push({ path: '/' });
        }
      }
    }
  }
}
