<template>
  <div class="flex justify-between items-center w-full h-16 shadow-lg px-4 py-2 bg-white">
    <div class="flex relative items-center w-48 h-full cursor-pointer" @click="systemMenu = !systemMenu">
      <img v-if="system" class="mr-4 h-full" :src="`/${system['logo']}`" />
      <img v-else class="mr-4 h-full" :src="`/${$store.state.btsLogo}`" />
      <div
        :class="{ 'translate-x-0': systemMenu, '-translate-x-52': !systemMenu, 'opacity-100': systemMenu, 'opacity-0': !systemMenu }"
        class="flex flex-col bg-white shadow-2xl px-4 absolute transform transition-all top-16">
        <img v-for="sys in systems" @click="$store.commit('setSystem', sys.id)"
          class="my-4 w-full border-2 border-transparent hover:border-gray-100 p-2"
          :key="sys.id"
          :src="`/${sys.logo}`" />
        <div 
          class="my-4 w-full border-2 border-transparent hover:border-gray-100 p-2 text-center font-bold"
          v-if="$route.name !== 'Dashboard'"
          @click="$router.push({ name: 'Dashboard' })">
          Return Home
        </div>
      </div>
      <p v-if="!system" class="text-gray-500">Select a system</p>
    </div>
    <div v-if="user" class="text-gray-700 cursor-pointer hover:underline" @click="$store.dispatch('logout')">
      {{ user.email }}
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch('loadUser');
  },
  data() {
    return {
      systemMenu: false,
    }
  },
  computed: {
    system() {
      return this.$store.getters.currentSystem;
    },
    systems() {
      return this.$store.state.systems;
    },
    user() {
      return this.$store.state.user;
    }
  },
}
</script>
