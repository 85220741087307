import Main from '../views/Main.vue';
import Login from '../components/Login.vue';

export default {
  path: '/',
  component: Main,
  children: [
    {
      name: 'Login',
      path: 'login',
      component: Login
    }
  ]
}
