const getLocalObj = function(loc, objKey) {
  if(!loc[objKey] || !JSON.parse(loc[objKey])) {
    loc[objKey] = JSON.stringify({});
  }
  return JSON.parse(loc[objKey]);
}

const setLocalItem = function(key, item) {
  const locStore = getLocalObj(localStorage, 'btsMarketing');
  locStore[key] = item;
  localStorage['btsMarketing'] = JSON.stringify(locStore);
  return JSON.parse(localStorage['btsMarketing']);
}

const getLocalItem = function(item) {
  const locStore = getLocalObj(localStorage, 'btsMarketing');
  return locStore[item];
}

export { setLocalItem, getLocalItem };
