<template>
  <div id="app" class="bg-gray-50">
    <router-view></router-view>
    <Loader 
      class="transform transition-all"
      :class="{ 'translate-x-full': !loading, 'translate-x-0': loading, 'opacity-0': !loading, 'opacity-100': loading }" />
  </div>
</template>
<script>
import Loader from './components/Loader.vue';
import './assets/css/style.module.scss';
export default {
  components: { Loader },
  computed: {
    loading() {
      const vm = this;
      return vm.$store.state.loading;
    }
  }
};
</script>
<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input, select {
      @apply w-full;
      @apply outline-none;
      @apply text-base;
      @apply border-b-2;
      @apply border-gray-100;
      @apply hover:bg-gray-50;
      @apply p-2;
  }
}
</style>
