<template>
  <div class="min-h-screen">
    <PortalNav />
    <router-view v-if="system && route !== 'Dashboard'"></router-view>
    <Dashboard v-else />
  </div>
</template>

<script>
import PortalNav from '../components/PortalNav';
import Dashboard from '../components/Dashboard';

export default {
  components: { PortalNav, Dashboard },
  computed: {
    system() {
      return this.$store.state.selectedSystem;
    },
    route() {
      return this.$route.name;
    }
  }
}
</script>
