<template>
  <div class="flex justify-center items-center h-screen">
    <div class="shadow-lg p-4 w-11/12 max-w-3xl h-auto bg-white">
      <div class="flex items-end border-b pb-2">
        <div class="border-r pr-4 mr-4 w-48 h-auto">
          <img class="" :src="`/${$store.state.btsLogo}`" />
        </div>
        <div>
          <h1 class="text-2xl md:text-4xl text-gray-800 w-full">Bicycle Transit Systems</h1>
          <p>Admin Portal</p>
        </div>
      </div>
      <img src="/microsoft.svg" @click="login" class="cursor-pointer mt-2 hover:shadow-lg duration-100" />
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    login() {
      this.$store.dispatch('login');
    }
  }
}
</script>
