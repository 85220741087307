import Vue from 'vue';
import Vuex from 'vuex';

// Stores
import mainStore from './main';
import userStore from './user';
import bikeshareForBusinessStore from './bikeshareForBusiness';
import pushNotifications from './pushNotifications';

Vue.use(Vuex);

const addStore = function(currentStore, newStore) {
  Object.keys(newStore).forEach(st => {
    if(currentStore[st]) {
      currentStore[st] = { ...currentStore[st], ...newStore[st] };
    } else {
      currentStore[st] = { ...newStore[st] };
    }
  });
}

const store = {};
addStore(store, mainStore);
addStore(store, userStore);
addStore(store, bikeshareForBusinessStore);
addStore(store, pushNotifications);
export default new Vuex.Store(store);
